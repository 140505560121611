import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Hidden, List, ListItemButton, ListItemText, SwipeableDrawer } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNavigation } from 'src/actions';
import { Logo } from '../Logo';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const MobileNav = (): JSX.Element => {
    const [openSidebar, setOpenSidebar] = useState<boolean>(false);
    const { navigation } = useNavigation();
    const navigate = useNavigate();

    const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }

        setOpenSidebar(open);
    };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box sx={{ m: 2, ml: 2 }} textAlign={'center'}>
                <Logo width={200} />
            </Box>

            <List>
                {navigation.list.map((item: any, index: number) => (
                    <ListItemButton
                        key={index}
                        divider
                        sx={{ display: item.id === '0e67f241-b0d1-44de-a784-ee56f275a7fv' ? 'none' : 'block' }}
                        onClick={() => {
                            (item.target === '_blank' && window.open(item.pathname, '_blank')) ||
                                navigate(item.pathname, { state: {
                                    type: item.type,
                                    id: item.categoryId,
                                } });
                        }}
                    >
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );

    return (
        <Hidden only={['md', 'lg', 'xl']}>
            <Button onClick={toggleDrawer('left', true)}>
                <MenuIcon />
            </Button>
            <SwipeableDrawer
                anchor={'left'}
                open={openSidebar}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
            >
                {list('left')}
            </SwipeableDrawer>
        </Hidden>
    );
};

export default MobileNav;
