import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

type TypeInitialState = {
  description?: string;
  address: {
    name: string;
    address: string;
    zip: string;
    city: string;
  },
  additionalInfo: {title: string; description: string}[],
  map: string;
};

const initialState: TypeInitialState = {
    description:
        'Naša firma spolupracuje s poprednými zahraničnými výrobcami, ktorí sú garanciou kvality. Zákazníkom tak dodávame kvalitné a spoľahlivé výrobky.',
    address: {
        name: 'STYL-KOZVAN s.r.o.',
        address: 'Bardejovská Zábava 13',
        zip: '085 01',
        city: 'Bardejov',
    },
    additionalInfo: [
        { title: 'IČO', description: '53 546 911' },
        { title: 'DIČ', description: '2121401106' },
        { title: 'IČ DPH', description: 'SK2121401106' },
        { title: 'Tel. kontakt', description: '0901 716 757, 0905 716 757' },
        { title: 'Email', description: 'info@stylkozvan.sk, vano.kozuby@gmail.com' },
        { title: 'Montáž', description: '0911 716 757' },
        { title: 'Otváracie hodiny', description: 'Po-Pia: 08:00 - 17:00, So: 08:00 - 11:00' },
    ],
    map: 'https://maps.google.com/maps?q=Predaj%C5%88a%20Styl%20Kozvan%2C%20Bardejovsk%C3%A1%20z%C3%A1bava%2013%2C%20085%2001%20Bardejov&t=m&z=13&output=embed&iwloc=near',
};

export const contactSlice = createSlice({
    name: 'contact',
    initialState,
    reducers: {},
});

export const useContact = () => {
    const contact = useSelector((state: any) => state.contact);

    return {
        contact,
    };
};

export default contactSlice.reducer;
