import { AppBar, Box, Container, Stack } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { useCms, useSeo } from 'src/actions';
import { Logo, Navigation } from 'src/components';
import { FooterLayout } from 'src/layouts';
import { animated, Spring } from 'react-spring';

export const ContentLayout = () => {
    const location = useLocation();
    const {
        cms: { pageInfo },
    } = useCms();
    const { seo } = useSeo();

    return (
        <>
            <Helmet>
                <title>{pageInfo?.title + ' | ' + seo.name}</title>
                <meta property="og:title" content={pageInfo?.title || seo.name} />
                <meta name="description" content={pageInfo?.description} />
                <link rel="canonical" href={`https://www.stylkozvan.sk${pageInfo?.pathname || location.pathname}`} />
            </Helmet>

            <AppBar position="fixed" sx={{ zIndex: 1 }}>
                <Container disableGutters={false} maxWidth={'xl'}>
                    <Stack
                        direction={'row'}
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={2}
                        sx={{ height: '80px' }}
                    >
                        <Logo />
                        <Navigation />
                    </Stack>
                </Container>
            </AppBar>
            <Box mt={14} />
            <Spring config={{duration: 500}} from={{ opacity: 0 }} to={{ opacity: 1 }}>
                {(styles: any) => (
                    <animated.div style={styles}>
                        <Outlet />
                    </animated.div>
                )}
            </Spring>
            <FooterLayout />
        </>
    );
};
