import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { FC, lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useApp } from 'src/actions';
import { ContentLayout, HomeLayout, Suspense } from 'src/layouts';
import { pathname } from 'src/routes/pathname';
import theme from 'src/theme/index';
import ArticleView from 'src/views/ArticleView';
import CategoryView from 'src/views/CategoryView';
import useGoogleAnalytics from '../hooks/useGoogleAnalytics';

const HomeView = lazy(() => import('src/views/HomeView'));
const ContactView = lazy(() => import('src/views/Contact'));
const PageView = lazy(() => import('src/views/PageView'));

type Props = {
    
}

const App: FC<Props> = () => {
    const { initApp } = useApp();
    const location = useLocation();
    const state = location.state as { type: string; id: string };
    useGoogleAnalytics()

    useEffect(() => {
        initApp();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getView = () => {
        switch (state?.type) {
            case 'category':
                return <CategoryView id={state.id} />;
            case 'article':
                return <ArticleView id={state.id} />;
            case 'page':
                return <PageView />;
            default:
                return <PageView />;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route element={<HomeLayout />}>
                    <Route index element={<Suspense><HomeView /></Suspense>} />
                </Route>
                <Route element={<ContentLayout />}>
                    <Route path={pathname.CONTACT} element={<Suspense><ContactView /></Suspense>} />
                    <Route path={'*'} element={<Suspense>{getView()}</Suspense>} />
                    <Route path="*" element={<p>Path not resolved</p>} />
                </Route>
            </Routes>
        </ThemeProvider>
    );
}

export default App;
