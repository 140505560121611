import {
    styled
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useNavigation } from 'src/actions';
import MuiPalette from 'src/theme/palette';

const Root = styled('div')(({ theme }) => ({
    [`& a`]: {
        color: MuiPalette.primary.main,
        margin: '1em',
        fontWeight: 400,
        textDecoration: 'none',
        fontFamily: 'Montserrat',
    },
    [`& a.active`]: {
        textDecoration: 'underline',
    },
}));

const FooterNav = () => {
    const { navigation, footerNavigation } = useNavigation();

    return  <Root>
    {footerNavigation.map((item: any, index: number) => (
        <NavLink
            key={index}
            to={item.pathname}
            state={{
                id: item.categoryId && item.categoryId,
            }}
            style={{
                color: '#fff',
                marginRight: index === navigation.list.length ? '0' : 'inherit',
            }}
        >
            {item.title}
        </NavLink>
    ))}
</Root>
}

export default FooterNav