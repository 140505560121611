import { configureStore } from '@reduxjs/toolkit'
import appSlice from "../actions/useApp";
import cmsSlice from "../actions/useCms";
import authSlice from "../actions/authorization/authorization.slice";
import navigationSlice from "src/actions/useNavigation";
import contactSlice from "src/actions/useContact";
import seoSlice from "src/actions/useSeo";
import { partnersSlice } from 'src/actions/usePartners';

export default configureStore({
  reducer: {
      app: appSlice,
      auth: authSlice,
      cms: cmsSlice,
      contact: contactSlice,
      navigation: navigationSlice,
      seo: seoSlice,
      partners: partnersSlice.reducer
  },
})