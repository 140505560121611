import ReactGA from 'react-ga4';


const ga4 = {
    init(TRACKING_ID: string) {
      ReactGA.initialize(TRACKING_ID);
    },
  
    sendEvent(hitType: string, page: string) {
      ReactGA.send({ hitType: hitType, page: page });
    },
  
    event(category: string, action: string, label: string) {
      ReactGA.event({ category: category, action: action, label: label });
    },
  };
  
  export default ga4;
