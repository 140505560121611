import { Box, Button, Hidden, Menu, MenuItem, useTheme } from '@mui/material';
import Fade from '@mui/material/Fade';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useNavigation } from 'src/actions';
import { TypeNavigation, TypeNavigationChildren } from 'src/types/navigation';
import Icon from './Icon';
import MobileNav from './navigation/MobileNav';

export const Navigation = (): JSX.Element => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const theme = useTheme();
    const { navigation } = useNavigation();
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Hidden only={['xs', 'sm']}>
                <Box display={'flex'} gap={3}>
                    {navigation.list.map(
                        (item: TypeNavigation, index: number) =>
                            (item.display &&
                                isEmpty(item.children) &&
                                ((item.target === null && (
                                    <NavLink
                                        key={index}
                                        to={item.pathname}
                                        id="composition-button"
                                        state={{
                                            type: item.type,
                                            id: item.categoryId,
                                        }}
                                        style={{
                                            lineHeight: '1em',
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            color: theme.palette.primary.main,
                                            //textTransform: 'uppercase',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {item.title}
                                    </NavLink>
                                )) || (
                                    <Button
                                        key={index}
                                        variant={'text'}
                                        onClick={() => {
                                            (item.target === '_blank' && window.open(item.pathname, '_blank')) ||
                                                navigate(item.pathname);
                                        }}
                                    >
                                        {item.title}
                                    </Button>
                                ))) || (
                                <Box key={index}>
                                    <NavLink
                                        key={index}
                                        to={item.pathname}
                                        id="fade-button"
                                        aria-controls={open ? 'fade-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleClick}
                                        style={{
                                            lineHeight: '3em',
                                            display: 'flex',
                                            alignItems: 'center',
                                            textDecoration: 'none',
                                            color: theme.palette.primary.main,
                                            // textTransform: 'uppercase',
                                            fontSize: '1rem',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {item.title} <Box ml={0.25} display={'inline-block'} />{' '}
                                        <Icon size={22}>DownArrow</Icon>
                                    </NavLink>
                                    <Menu
                                        id="fade-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'fade-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        TransitionComponent={Fade}
                                        elevation={1}
                                    >
                                        {item.children.map((iitem: TypeNavigationChildren, index: number) => (
                                            <MenuItem
                                                key={index}
                                                component={'a'}
                                                dense={true}
                                                divider
                                                onClick={() => {
                                                    (iitem.target === '_blank' &&
                                                        window.open(iitem.pathname, '_blank')) ||
                                                        navigate(iitem.pathname);
                                                    handleClose();
                                                }}
                                                sx={{
                                                    color: theme.palette.primary.main,
                                                    textTransform: 'uppercase',
                                                }}
                                            >
                                                {iitem.title}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            ),
                    )}
                </Box>
            </Hidden>

            <MobileNav />
        </>
    );
};
