import React from 'react';
import { useLocation } from 'react-router-dom';
import ga4 from 'src/utils/ga4';

const useGoogleAnalytics = () => {
  const location = useLocation();
  const currentPath = location.pathname + location.search;

  React.useEffect(() => {
    ga4.sendEvent('pageview', currentPath);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const sendEvent = (hitType: string) => ga4.sendEvent(hitType, currentPath);

  return {
    sendEvent,
  };
};

export default useGoogleAnalytics;
