import { Container, Grid, Stack } from '@mui/material';
import { FC, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { TypeArticle, TypeGallery } from 'src/types/cms';

type Props = {
    data: TypeGallery[];
    columns?: number;
    length?: TypeArticle['galleryLength'];
    path?: TypeArticle['galleryPath'];
};

type TypeGalleryList = {
    data?: TypeGallery;
    columns?: number;
    length?: TypeArticle['galleryLength'];
    path?: TypeArticle['galleryPath'];
};

const GalleryList: FC<TypeGalleryList> = ({ data, path, length, columns = 6 }) => {
    const [index, setIndex] = useState(-1);
    const handleClick = (index: number) => setIndex(index);
    const handleClose = () => setIndex(-1);
    const handleMovePrev = (prevIndex: number) => setIndex(prevIndex);
    const handleMoveNext = (nextIndex: number) => setIndex(nextIndex);
    // const { images } = data;
    const url = 'https://gallery.stylkozvan.sk'

    const getArrayLength = () => {
        const arr = [...Array(length)].map((i, ii) => {
            if(ii < 10) {
                return `${url}${path}/00${ii}.jpg`
            }
            if(ii > 9 && ii < 100) {
                return `${url}${path}/0${ii}.jpg`
            }

            return  `${url}${path}/${ii}.jpg`
        });

        return arr
    };

    const currentImage = getArrayLength()[index];
    const nextIndex = (index + 1) % getArrayLength().length;
    const nextImage = getArrayLength()[nextIndex] || currentImage;
    const prevIndex = (index + getArrayLength().length - 1) % getArrayLength().length;
    const prevImage = getArrayLength()[prevIndex] || currentImage; 
   

    return (
        <Container maxWidth={false} sx={{ mb: 2 }}>
            <Grid container spacing={1}>
   
                 {getArrayLength()?.map((fileName: string, ii: number) => {
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            lg={2}
                            key={ii}
                            sx={{  height: '250px', overflow: 'hidden' }}
                        >
                            <LazyLoadImage
                                alt={fileName}
                                height={'100%'}
                                src={fileName} // use normal <img> attributes as props
                                width={'100%'}
                                onClick={() => handleClick(ii)}
                                style={{
                                    objectFit: 'cover',
                                    filter: 'none',
                                    borderRadius: '8px',
                                }}
                                threshold={200}
                                effect="blur"
                            />
                        </Grid>
                    );
                })} 
            </Grid>

             {!!currentImage && (
                <Lightbox
                    mainSrc={currentImage}
                    imageTitle={currentImage}
                    mainSrcThumbnail={currentImage}
                    nextSrc={nextImage}
                    nextSrcThumbnail={nextImage}
                    prevSrc={prevImage}
                    prevSrcThumbnail={prevImage}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={() => handleMovePrev(prevIndex)}
                    onMoveNextRequest={() => handleMoveNext(nextIndex)}
                />
            )} 
        </Container>
    );
};

const Gallery: FC<Props> = ({ data, path, length = 0, columns = 6 }) => {
    return (
        <Stack spacing={2}>
            <GalleryList path={path} length={length} columns={columns} />;
        </Stack>
    );

    /*  return (
        <Stack spacing={2}>
            {data.map((item, index: number) => {
                return <GalleryList key={index} path={path} length={length} columns={columns} />;
            })}
        </Stack>
    ); */
};

export default Gallery;
