import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { pathname } from 'src/routes/pathname';
import { TypeNavigation } from 'src/types/navigation';

const list: TypeNavigation[] = [
    {
        id: '0e67f241-b0d1-44de-a784-ee56f275a7fc',
        title: 'Home',
        ico: null,
        pathname: pathname.HOME,
        children: [],
        footerNav: true,
        display: true,
        target: null,
        type: 'home'
    },
    {
        id: '0e67f241-b0d1-44de-a784-ee56f275a7fc',
        title: 'Eshop',
        ico: null,
        pathname: pathname.EXT_SHOP,
        children: [],
        footerNav: true,
        display: true,
        target: '_blank',
        type: 'home'
    },
    {
        id: '0e67f241-b0d1-44de-a784-ee56f275a7fc',
        title: 'Veľkoobchod',
        ico: null,
        pathname: `${pathname.EXT_SHOP}/index.php?controller=authentication&back=my-account`,
        children: [],
        footerNav: true,
        display: true,
        target: '_blank',
        type: 'home'
    },
    {
        id: '0e67f241-b0d1-44de-a784-ee56f275a7fv',
        title: 'Produkty',
        ico: null,
        pathname: '',
        children: [
            {
                id: '1e67f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Murované komínové systémy',
                pathname: 'https://www.kozuby-vano.sk/343-murovane-kominove-systemy',
                target: '_blank'

            },
            {
                id: '1567f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Nerezové komínové systémy',
                pathname: 'https://www.kozuby-vano.sk/522-nerezove-kominove-systemy',
                target: '_blank'
            },
            {
                id: '1167f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Krbové vložky',
                pathname: 'https://www.kozuby-vano.sk/5-krbove-vlozky',
                target: '_blank'

            },
            {
                id: '1667f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Krbové kachle',
                pathname: 'https://www.kozuby-vano.sk/6-krbove-kachle',
                target: '_blank'
            },

            {
                id: '1267f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Sporáky',
                pathname: 'https://www.kozuby-vano.sk/7-sporaky',
                target: '_blank'

            },
            {
                id: '1367f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Elektrické krby',
                pathname: 'https://www.kozuby-vano.sk/8-elektricke-krby',
                target: '_blank'

            },
            {
                id: '1467f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Grily',
                pathname: 'https://www.kozuby-vano.sk/294-grily-',
                target: '_blank'
            },
            {
                id: '1767f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Biokrby',
                pathname: 'https://www.kozuby-vano.sk/103-biokrby-biokozuby',
                target: '_blank'
            },
            {
                id: '1867f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Záhradné krby',
                pathname: 'https://www.kozuby-vano.sk/125-zahradne-krby',
                target: '_blank'
            },
            {
                id: '1967f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Pizza pece',
                pathname: 'https://www.kozuby-vano.sk/177-pizza-pece',
                target: '_blank'
            },
            {
                id: '1077f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Stavebný materiál',
                pathname: 'https://www.kozuby-vano.sk/10-stavebny-material',
                target: '_blank'
            },
            {
                id: '1467f241-b0d1-44de-a784-ee56f275a7fv',
                title: 'Príslušenstvo',
                pathname: 'https://www.kozuby-vano.sk/110-prislusenstvo',
                target: '_blank'
            }
        ],
        footerNav: false,
        display: true,
        target: null,
        type: null
    },
    {
        id: '0e67f241-b0d1-44de-a784-ee56f275a7ff',
        title: 'Galéria',
        ico: null,
        pathname: pathname.GALLERY,
        display: true,
        children: [
           /*  {
                id: '1e67f241-b0d1-44de-a784-ee56f275a7ff',
                title: 'Krbové kachle',
                pathname: pathname.GALLERY + '/krbove-kachle',
                target: null,
                type: 'category',
                categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e'
            }, */
        ],
        footerNav: false,
        target: null,
        type: 'category',
        categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e'
    },
    {
        id: '01e67238-b4da-47e3-bcbe-8a0ff52c971w',
        title: 'Na stiahnutie',
        ico: null,
        pathname: pathname.DOWNLOAD,
        display: true,
        children: [],
        footerNav: true,
        target: null,
        type: 'page'
    },
    {
        id: '01e67238-b4ra-47e3-bcbe-8a0ff52c971w',
        title: 'Partneri',
        ico: null,
        pathname: pathname.PARTNERS,
        display: true,
        children: [],
        footerNav: false,
        target: null,
        type: 'page'
    },
    {
        id: '94435691-45c9-4f4a-820d-481578f4dd1f',
        title: 'O nás',
        ico: null,
        pathname: pathname.ABOUTUS,
        display: true,
        children: [],
        footerNav: true,
        target: null,
        type: 'aboutus'
    },
    {
        id: '94435691-46c9-4f4a-820d-481578f4dd1f',
        title: 'Kontakt',
        ico: null,
        pathname: pathname.CONTACT,
        display: true,
        children: [],
        footerNav: true,
        target: null,
        type: null
    },
];

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        list,
    },
    reducers: {},
});

export const useNavigation = () => {
    const navigation = useSelector((state: any) => state.navigation);
    const footerNavigation = navigation.list.filter((item: any) => item.footerNav)

    return {
        navigation,
        footerNavigation
    };
};

export default navigationSlice.reducer;
