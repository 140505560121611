import { FC, useMemo } from 'react';
import { useCms } from 'src/actions';
import CmsArticle from 'src/components/CmsArticle';

type Props = {
    id: string
};

const ArticleView: FC<Props> = ({ id }) => {
   
    const {
        cms: { categories, articles },
    } = useCms();

    const getArticle = () => {
        const find = articles.find((item: { [x: string]: any }) => item['id'] === id);

        return find && { ...find, content: find.content && find.content.split('\n').map((str: string) => <p>{str}</p>) };
    };

    const getCategory = () => {
        const find = categories.find((item: { id: number }) => item.id === getArticle()?.categoryId);

        return find && find;
    };

    return (
        <>
            {useMemo(
                () => (
                   <CmsArticle data={getArticle()} category={getCategory()} />
                ),

                // eslint-disable-next-line react-hooks/exhaustive-deps
                [],
            )}
        </>
    );
};

export default ArticleView;
