import { Box, Container, Divider, Hidden, IconButton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useApp } from 'src/actions';
import { Icon, Logo } from 'src/components';
import FooterNav from 'src/components/navigation/FooterNav';

export const FooterLayout = (): JSX.Element => {
    const { app } = useApp();

    return (
        <Box py={4} sx={{ background: '#212121' }}>
            <Container disableGutters={false} maxWidth={'xl'}>
                <Box>
                    <Hidden only={['xs']}>
                        <Stack direction={'row'} justifyContent="space-between" alignItems="center" spacing={2}>
                            <Logo width={150} variant={'white'} />
                            <Box>
                                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                                <FooterNav />
                                    <IconButton
                                        onClick={() => {
                                            window.open('https://www.facebook.com/styl.kozvan.3', '_blank');
                                        }}
                                    >
                                        <Icon color={'#fff'}>Facebook</Icon>
                                    </IconButton>
                                </Stack>
                            </Box>
                        </Stack>
                        <Box mt={2} />
                        <Divider sx={{ background: '#fff', height: '2px', opacity: '0.5' }} />
                    </Hidden>

                    <Hidden only={['xs']}>
                        <Stack
                            direction={'row'}
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ mt: 1 }}
                            spacing={2}
                        >
                            <Typography fontSize={'0.9rem'} color={'#fff'}>
                                {app.copyright}
                            </Typography>
                            <Typography fontSize={'0.9rem'} color={'#fff'} sx={{ '& a': { color: '#fff' } }}>
                                Made by <Link to={'#'} onClick={() => window.open('https://www.weblab.ltd', '_blank')}>
                                    Weblab
                                </Link>
                            </Typography>
                        </Stack>
                    </Hidden>

                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                        <Stack alignItems="center" spacing={2}>
                            <Box my={1}>
                                <Logo width={80} variant={'white'} />
                            </Box>
                            <Box>
                                <Typography fontSize={'0.9rem'} color={'#fff'} textAlign={'center'}>
                                    {app.company}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography fontSize={'0.9rem'} color={'#fff'} textAlign={'center'}>
                                    {app.copyright}
                                </Typography>
                            </Box>
                            <Typography fontSize={'0.9rem'} color={'#fff'} sx={{ '& a': { color: '#fff' } }}>
                                Made by <Link to={'#'} onClick={() => window.open('https://www.weblab.ltd', '_blank')}>
                                    Weblab
                                </Link>
                            </Typography>
                        </Stack>
                    </Hidden>
                </Box>
            </Container>
        </Box>
    );
};
