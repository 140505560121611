import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
//import { pathname } from 'src/routes/pathname';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pathname } from 'src/routes/pathname';
import { TypeArticle, TypeCategory, TypePage } from 'src/types/cms';
import { TypeSeo } from 'src/types/seo';

type TypeState = {
    categories: TypeCategory[];
    articles: TypeArticle[];
    pages: TypePage[];
    pageInfo: { title: string; description: string; pathname: string };
};

const initialState: TypeState = {
    articles: [
        {
            id: '01e67238-b3da-47e3-bbbe-8a0ff52c971e',
            title: 'Krbové kachle',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/krbove-kachle',
            content: null,
            createdAt: '',
            updatedAt: '',
            image: 'https://gallery.stylkozvan.sk/_thumbs/krbove.webp',
            galleryLength: 94,
            galleryPath: '/krbove_kachle'
        },
        {
            id: '01e67238-b3da-47e3-bbbe-8a0ff52c971r',
            title: 'Moderné krby',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/moderne-krby',
            content: null,
            createdAt: '',
            updatedAt: '',
            image: 'https://gallery.stylkozvan.sk/_thumbs/moderne.webp',
            galleryLength: 310,
            galleryPath: '/moderne_krby'
        },
        {
            id: '01e67238-b3da-47e4-bbbe-8a0ff52c971r',
            title: 'Kachľové krby',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/kachlove-krby',
            content: null,
            createdAt: '',
            updatedAt: '',
            image: 'https://gallery.stylkozvan.sk/_thumbs/kachlove.webp',
            galleryLength: 141,
            galleryPath: '/kachlove_krby'
        },
        {
            id: '01e67238-b3da-47e5-bbbe-8a0ff52c971r',
            title: 'Záhradne zostavy',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/zahradne-zostavy',
            content: null,
            createdAt: '',
            updatedAt: '',
            image: 'https://gallery.stylkozvan.sk/_thumbs/zahradne.webp',
            galleryLength: 53,
            galleryPath: '/zahradne_zostavy'
        },
        {
            id: '01e67238-b3da-47e6-bbbe-8a0ff52c971r',
            title: 'Sporáky',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/sporaky',
            content: null,
            createdAt: '',
            updatedAt: '',
            image: 'https://gallery.stylkozvan.sk/_thumbs/sporaky.webp',
            galleryLength: 39,
            galleryPath: '/sporaky'
        },
        {
            id: '01e67238-b3da-47e7-bbbe-8a0ff52c971r',
            title: '3D návrhy',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/3d-navrhy',
            content: null,
            createdAt: '',
            updatedAt: '',
            image: 'https://gallery.stylkozvan.sk/3d_navrhy/001.jpg',
            galleryLength: 49,
            galleryPath: '/3d_navrhy'
        },
        {
            id: '01e67238-b3da-47e8-bbbe-8a0ff52c971r',
            title: 'Komínové systémy',
            categoryId: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            description: null,
            pathname: pathname.GALLERY + '/kominove-systemy',
            content: null,
            createdAt: '',
            updatedAt: '', 
            image: 'https://gallery.stylkozvan.sk/_thumbs/kominove.webp',
            galleryLength: 18,
            galleryPath: '/kominove_systemy'
        },
    ],
    categories: [
        {
            id: '01e67238-b3da-47e3-bcbe-9a0ff52c971e',
            title: 'Galéria',
            description: '',
            parentId: null,
            pathname: pathname.GALLERY,
        },
    ],
    pages: [
        {
            id: '01e67238-b3da-47e3-bcbe-8a0ff52c971e',
            title: 'Komíny, kachle, krby',
            description:
                'Autorizovaný predajca krbov, kachlí, komínových systémov a grilov. Ponúkame odborné poradenstvo a montáž pri zakúpení výrobku od nás.',
            pathname: '/',
            content: '',
            image: '',
            gallery: [],
        },
        {
            id: '01e67238-b3da-47e3-bcbe-8a0ff52c971e',
            title: 'O nás',
            description:
                'Firma sa zaoberá maloobchodným a veľkoobchodným predajom krbových vložiek, kachlí, komínov a grilov.',
            pathname: pathname.ABOUTUS,
            content:
                'V našej ponuke nájdete značky ako Prodmax, Romotop, Kobok, ABX, Kratki, Thorma, CJ Blok a iné. Ponúkame tiež komplexne služby pri výstavbe krbov a taktiež komínov. Sme členom Cechu kachliarov a krbárov Slovenskej republiky.',
            image: '',
            type: 'about',
            gallery: [
                {
                    title: 'O nás',
                    images: [
                        {
                            src: 'https://gallery.stylkozvan.sk/about_us/03.jpg',
                            title: 'img',
                        },
                        {
                            src: 'https://gallery.stylkozvan.sk/about_us/04.jpg',
                            title: 'img',
                        },
                        {
                            src: 'https://gallery.stylkozvan.sk/about_us/05.jpg',
                            title: 'img',
                        },
                        {
                            src: 'https://gallery.stylkozvan.sk/about_us/06.jpg',
                            title: 'img',
                        },
                        {
                            src: 'https://gallery.stylkozvan.sk/about_us/01.jpg',
                            title: 'img',
                        },
                        {
                            src: 'https://gallery.stylkozvan.sk/about_us/02.jpg',
                            title: 'img',
                        },
                    ],
                },
            ],
        },
        {
            id: '01e67238-b4da-47e3-bcbe-8a0ff52c971e',
            title: 'Na stiahnutie',
            description:
                'Naša firma spolupracuje s poprednými zahraničnými výrobcami, ktorí sú garanciou kvality. Zákazníkom tak dodávame kvalitné a spoľahlivé výrobky.',
            pathname: pathname.DOWNLOAD,
            content: '',
            image: '',
            gallery: [],
            download: [
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Komínové Systémy PRODMAX 6/2021',
                    description: 'Kompletný cenník nerezových a oceľových komínových systémov výrobcu PRODMAX.',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/cenn%C3%ADk_prodmax_2021_8.pdf',
                },
                {
                    id: '01e67202-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Komínové Systémy CJ BLOK 7/2021',
                    description: 'Kompletný cenník komínových systémov a prvkov výrobcu CJ BLOK.',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/CJ%20BLOK%20kom%C3%ADnov%C3%A9%20syst%C3%A9my%202021.pdf',
                },
                {
                    id: '01e67202-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník ROMOTOP 9/2021',
                    description: 'Kompletný cenník krbových kachlí a vložiek ROMOTOP 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/cenik202109sk.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník ROMOTOP IMPRESSION 8/2021',
                    description: 'Kompletný cenník krbových vložiek ROMOTOP IMPRESSION 8/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/ceny%20impression%2001_08_2021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník HEIN 8/2021',
                    description: 'Kompletný cenník krbových kachlí výrobcu HEIN 8/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/cenik_hein_2021_08_sk.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník HS FLAMINGO 4/2021',
                    description: 'Kompletný cenník krbových kachlí a vložiek HS FLAMINGO 4/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/ccenn%C3%ADk_hs_flamingo.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník THORMA 4/2021',
                    description: 'Kompletný cenník krbových kachlí a vložiek THORMA 4/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/thorma%20%C5%A1tandard%202021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník THORMA EXCLUSIVE 4/2021',
                    description: 'Kompletný cenník krbových kachlí THORMA EXCLUSIVE 4/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/thorma%20excluscive%202021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník ABX 10/2021',
                    description: 'Kompletný cenník krbových kachlí a vložiek ABX 10/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/abx_102021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník ABX Exclusive 10/2021',
                    description: 'Kompletný cenník krbových kachlí a vložiek ABX Exclusive 10/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/abx_exclusive_102021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kratki 09/2021',
                    description: 'Kompletný cenník KRATKI 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kratki_092021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kobok Príslušenstvo 9/2021',
                    description: 'Kompletný cenník krbových vložiek KOBOK PRÍSLUŠENSTVO 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kobok_prislusenstvo_092021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kobok Chopok 9/2021',
                    description: 'Kompletný cenník krbových vložiek KOBOK CHOPOK 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kobok_chopok_092021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kobok Chopok Prizma 9/2021',
                    description: 'Kompletný cenník krbových vložiek KOBOK CHOPOK PRIZMA 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kobok_chopok__prizma_092021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kobok Chopok Panorama 9/2021',
                    description: 'Kompletný cenník krbových vložiek KOBOK CHOPOK PANORAMA 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kobok_chopok_panorama_092021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kobok Kazeta 9/2021',
                    description: 'Kompletný cenník krbových vložiek KOBOK KAZETA 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kobok_chopok_kazeta_092021.pdf',
                },
                {
                    id: '01e67201-b4da-47e3-bcbe-8a0ff52c971e',
                    title: 'Cenník Kobok Bystra 9/2021',
                    description: 'Kompletný cenník krbových vložiek KOBOK BYSTRA 9/2021',
                    fileType: 'PDF',
                    pathname: 'https://download.stylkozvan.sk/kobok_bystra_092021.pdf',
                },
            ],
        },
        {
            id: '01e67238-b4da-77e3-bcbe-8a0ff52c971e',
            title: 'Partneri',
            description:
                'Naša firma spolupracuje s poprednými zahraničnými výrobcami, ktorí sú garanciou kvality. Zákazníkom tak dodávame kvalitné a spoľahlivé výrobky.',
            pathname: pathname.PARTNERS,
            content: '',
            image: '',
            gallery: [],
        },
    ],
    pageInfo: {
        title: '',
        description: '',
        pathname: '',
    },
};

export const cmsSlice = createSlice({
    name: 'cms',
    initialState,
    reducers: {
        pageInfo: (state, action) => {
            state.pageInfo = action.payload;
        },
    },
});

export const useCms = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const cms = useSelector((state: any) => state.cms);
    const { pageInfo } = cmsSlice.actions;

    useEffect(() => {
        let obj: TypeSeo = {
            title: null,
            description: null,
            pathname: null,
        };
        const a = cms.articles.find((obj: TypeArticle) => location.pathname.includes(obj['pathname']));
        const c = cms.categories.find((obj: TypeCategory) => location.pathname.includes(obj['pathname']));
        const p = cms.pages.find((obj: TypePage) => location.pathname.includes(obj['pathname']));

        obj['title'] = a?.title || c?.title || p?.title || '--';
        obj['description'] = a?.description || c?.description || p?.description || '--';
        obj['pathname'] = a?.pathname || c?.pathname || p?.pathname || '--';

        dispatch(pageInfo(obj));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return {
        cms,
    };
};

export default cmsSlice.reducer;
