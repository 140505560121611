import { Box, Container, Typography } from '@mui/material';
import { FC } from 'react';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

type SlideType = { title: string; description: string; description2: string; href: string };

type Props = {
    list: SlideType[];
    autoplay?: boolean;
    duration?: number;
    arrows?: boolean;
    indicators?: boolean;
};

export const Slideshow: FC<Props> = ({
    list,
    autoplay = true,
    duration = 5000,
    indicators = false,
    arrows = false,
}) => {
    const zoomInProperties = {
        indicators,
        scale: 1.4,
        autoplay: autoplay,
        duration: duration,
        arrows: arrows,
        pauseOnHover: false
    };

    return (
        <Box className="slide-container">
            <Zoom easing="ease" {...zoomInProperties}>
                {list.map((item: SlideType, index: number) => (
                    <Box key={index} className="each-slide">
                        <Box
                            sx={{
                                backgroundImage: `url(${item.href})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                            height={'620px'}
                            display={{ xs: 'flex', sm: 'flex' }}
                            flexWrap={{ xs: 'wrap' }}
                            alignContent={{ xs: 'center' }}
                            justifyContent={{ xs: 'center', sm: 'center' }}
                        >
                            <Container
                                maxWidth={'sm'}
                                disableGutters
                                sx={{ padding: '1em', background: 'rgba(0,0,0,0.7)', textAlign: 'center' }}
                            >
                                <Box component={'img'} src={'https://gallery.stylkozvan.sk/logo/stylkozvan3.png'} />
                                <Typography variant={'h3'} color={'#fff'} fontWeight={900} mb={0}>
                                    {item.description}
                                </Typography>
                                <Typography variant={'h5'} color={'#fff'} fontWeight={700}>
                                    {item.description2}
                                </Typography>
                            </Container>
                        </Box>
                    </Box>
                ))}
            </Zoom>
        </Box>
    );
};
