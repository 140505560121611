import { Box } from '@mui/material';
import { FC } from 'react';

type Props = {
    width?: number;
    variant?: string;
};

export const Logo: FC<Props> = ({ variant, width = 250 }) => {
    if (variant === 'white') {
        return <Box component={'img'} height={64} src={'https://gallery.stylkozvan.sk/logo/stylkozvan3.png'} />;
    }

    return (
        <Box
            component={'img'}
            width={width}
            //height={64}
            src={'https://gallery.stylkozvan.sk/logo/logo_stylkozvan.png'}
            alt="STYLKOZVAN"
        />
    );
};
