import { Box, Container, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import React, { FC } from "react";
import { TypeDownload } from "src/types/cms";
import Icon from "./Icon";

type Props = {
    data?: TypeDownload[]
}

const FileList: FC<Props> = ({ data }) => {
    return <Container maxWidth={'md'}>
        <Box mt={2} />
        <List dense={false}>
            {data && data.map((file, index) => <ListItem key={index} divider
                  secondaryAction={
                    <IconButton title={file.title} edge="end" aria-label="download" onClick={() => window.open(file.pathname, '_blank')}>
                      <Icon size={28}>Download</Icon>
                    </IconButton>
                  }
                >
                  <ListItemText
                    primary={<Typography variant={'h4'}>{file.title}</Typography>}
                    secondary={file.description}
                  />
                </ListItem>)}
            </List>
            <Box mb={2} />
    </Container>
}

export default FileList