import { Box, Card, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from 'src/theme';
import { TypeArticle } from 'src/types/cms';

type Props = {
    items: TypeArticle[];
    columns?: number;
};

const CategoryItemsList: FC<Props> = ({ items, columns = 5 }) => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Grid container spacing={2}>
            {items &&
                items.map((item: any, index: number) => {
                    return (
                        <Grid
                            key={index}
                            item
                            display={{ xs: 'flex', md: 'inline' }}
                            justifyContent={{ xs: 'center', md: 'flex-start' }}
                            width={{ xs: '100%', sm: '50%', md: '33%'}}
                        >
                            <Card
                                elevation={0}
                                sx={{ cursor: 'pointer', maxWidth: '100%' }}
                                onClick={() => navigate(item['pathname'], { state: { type: 'article', id: item.id } })}
                            >

                                <Box sx={{ background: '#F2F2F2' }}>
                                    <CardMedia component="img" height={isMobile ? 'auto' : '250px'} sx={{ borderRadius: '4px'  }} image={item.image} alt={item['title']} />

                                </Box>
                                <CardContent sx={{ px: 0 }}>
                                    <Typography variant={'h4'} lineHeight={'1.2em'}>
                                        {item['title']}
                                    </Typography>
                                    <Typography>{item['description']}</Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
        </Grid>
    );
};

export default CategoryItemsList;
