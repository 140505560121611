import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { TypePartner, usePartners } from 'src/actions/usePartners';

type Props = {
    showTitle?: boolean;
};

const PartnersLogo: FC<Props> = ({ showTitle = false }) => {
    const { partners } = usePartners();
    return (
        <Container>
            <Stack mt={4} mb={8} alignItems={'center'} justifyContent={'center'}>
                {showTitle && <Typography variant="h2">Partneri</Typography>}
                <Grid container spacing={2}>
                    {partners &&
                        partners.map((item: TypePartner, index: number) => (
                            <Grid key={index} item xs={12} sm={6} md={3} lg={2} textAlign={'center'}>
                                <Box
                                    key={index}
                                    component={'img'}
                                    src={`data:image/png;base64,` + item.logo}
                                    sx={{ width: { xs: 150 } }}
                                />
                            </Grid>
                        ))}
                </Grid>
            </Stack>
        </Container>
    );
};

export default PartnersLogo;
