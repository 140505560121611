import '@fontsource/montserrat';
import { createTheme } from '@mui/material';
import MuiPalette from './palette';

/**
 * NPM : https://www.npmjs.com/package/@fontsource/montserrat
 * Defaults to weight 400.
 * Supported variables:
 * Weights: [100,200,300,400,500,600,700,800,900]
 * Styles: [italic,normal]
 * Supported subsets: [cyrillic,cyrillic-ext,latin,latin-ext,vietnamese]
 *
 */

let theme = createTheme({
    // ...
});

export const headersFontFamily = 'Montserrat';

const MuiTypography = createTheme(theme, {
    fontFamily:
        '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Noto Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif',
    h1: {
        fontFamily: headersFontFamily,
        fontSize: '3rem',
        fontWeight: 600,
        letterSpacing: '-2px',
        color: MuiPalette.primary.main,
        margin: '1rem 0 3rem',
    },
    h2: {
        fontFamily: headersFontFamily,
        fontSize: '2rem',
        fontWeight: 600,
        lineHeight: 1.5,
        color: MuiPalette.primary.main,
        mt: 1,
        marginBottom: '0.5em',
    },
    h3: {
        fontFamily: headersFontFamily,
        fontSize: '1.5em',
        fontWeight: 600,
        lineHeight: 1.5,
        color: MuiPalette.primary.main,
        marginBottom: '0.5em',
    },
    h4: {
        fontFamily: headersFontFamily,
        fontSize: '1.3rem',
        fontWeight: 600,
        lineHeight: 2,
        color: MuiPalette.primary.main,
    },
    h5: {
        fontSize: '1.0rem',
        fontWeight: 600,
        color: MuiPalette.primary.main,
        marginBottom: '0.5em',
    },
    h6: {
        fontSize: '0.9rem',
        fontWeight: 600,
        color: MuiPalette.primary.main,
        marginTop: '0.5em',
        marginBottom: '0',
    },
    body1: {
        fontFamily: headersFontFamily,
        color: MuiPalette.primary.main,

        [theme.breakpoints.only('xl')]: {
            fontSize: '1.1rem',
        },
    },
    body2: {
        marginBottom: '0.5rem',
        fontSize: '0.9rem',
    },
    button: {
        textTransform: 'inherit',
        color: MuiPalette.text.secondary,
    },
    caption: {},
    subtitle1: {},
    subtitle2: {},
});

export default MuiTypography;
