import * as ReactDOMClient from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "src/views/App";
import reportWebVitals from "./reportWebVitals";
import ga4 from "./utils/ga4";
import store from "./utils/store";

if (process.env.REACT_APP_GOOGLE_ANALITCS_ID && window.location.origin === 'https://www.stylkozvan.sk'  ) {
  ga4.init(process.env.REACT_APP_GOOGLE_ANALITCS_ID as string);
}

const container = document.getElementById("root");
// @ts-ignore
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <HelmetProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </HelmetProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
