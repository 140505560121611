import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        api: null,
        langs: {
            list: ['sk'],
            selected: 'sk',
        },
        domain: 'stylkozvan.sk',
        company: 'Stylkozvan - kozuby, krby, online predaj',
        copyright: '© Stylkozvan 2023',
    },
    reducers: {
        init: (state, action) => {
            state.api = action.payload.api;
        },
    },
});

export const useApp = () => {
    const dispatch = useDispatch();
    const app = useSelector((state: any) => state.app);
    const lang = useSelector((state: any) => state.app.langs.selected);

    const initApp = () => {
        dispatch({
            type: 'app/init',
            payload: {
                api: 'http://127.0.0.1:3333/',
            },
        });
    };

    const incLangCode = (var1: string, var2: string) => {
        return var1 + '_' + var2;
    };

    return {
        app,
        lang,
        initApp,
        incLangCode,
    };
};

export default appSlice.reducer;
