import { Container, Typography } from '@mui/material';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useCms } from 'src/actions';
import CategoryItemsList from 'src/components/CategoryItemsList';
import { TypeArticle, TypeCategory } from 'src/types/cms';

type Props = {
    id: string;
};

const CategoryView: FC<Props> = ({ id }) => {
    const location = useLocation();
    const {
        cms: { categories, articles },
    } = useCms();

    const category = categories && categories.find((obj: TypeCategory) => obj.pathname.includes(location.pathname));
    const items = articles && articles.filter((obj: TypeArticle) => obj.categoryId === id);

    return (
        <>
            {useMemo(() => {
                return (
                    category && (
                        <>
                            <Container maxWidth={'xl'}>
                                <Typography variant={'h1'}>{category['title']}</Typography>
                            </Container>
                            <Container maxWidth={'xl'} disableGutters={false} sx={{ mt: 4, mb: 10 }}>
                                <Typography>
                                    {category['description'] &&
                                        category['description'].split('\n').map((str: any) => <p>{str}</p>)}
                                </Typography>

                              {/*   {categories.map((item: any, index: number) => {
                                    if (item.parentId === id) {
                                        return <CategoryItems {...item} key={index} />;
                                    }

                                    return null;
                                })} */}

                                <CategoryItemsList items={items} columns={5} />
                            </Container>
                        </>
                    )
                );

                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [category])}
        </>
    );
};

export default CategoryView;
