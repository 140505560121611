import React, { FC, ReactNode } from "react";

type Props = {
children: ReactNode
}

export const Suspense: FC<Props> = ({ children}) => {
   return (
     <React.Suspense fallback={<>Loading...</>}>{children}</React.Suspense>
   );
 };