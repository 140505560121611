const MuiPalette = {
    action: {
        disabled: 'rgba(0, 0, 0, 0.26)',
        disabledBackground: 'rgba(0, 0, 0, 0.05)',
        disabledOpacity: 0.38,
    },
    primary: {
        main: '#000',
        contrastText: '#63b5e5',

        dark: '#11344f',
    },
    secondary: {
        main: '#CF261F',
    },
    text: {
        primary: '#42526e',
        secondary: '#42526e',
        disabled: 'rgba(0,0,0,0.3)',
    },
    error: {
        main: '#f57c7c',
        dark: '#c96969',
        contrastText: '#fff',
    },
    warning: {
        main: '#FFEBA5',
        contrastText: '#42526e',
    },
    info: {
        main: 'rgba(0, 0, 0, 0.23)',
        contrastText: '#fff',
    },
    success: {
        main: '#3dbf8a',
        contrastText: '#fff',
    },
    grey: {
        highlight: '#FDE0E0',
        even: '#f4f6f8',
        marked: '#D9D9D9',
    },
};

export default MuiPalette;
