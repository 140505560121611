import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { pathname } from 'src/routes/pathname';
import { TypeArticle, TypeCategory } from 'src/types/cms';
import FileList from './FileList';
import Gallery from './Gallery';
import PartnersLogo from './PartnersLogo';

type Props = {
    data: TypeArticle;
    category?: TypeCategory;
};

const CmsArticle: FC<Props> = ({ data, category }) => {
    const { title, description, content, list, gallery, galleryPath, galleryLength, download } = data;
    //const isGallery = category?.pathname.includes('/galeria');
    const location = useLocation();

    return (
        <>
            <Container maxWidth={'xl'}>
                <Typography variant={'h1'} textAlign={'center'} mb={1}>
                    {title}
                </Typography>
                {category && (
                    <Typography textAlign={'center'} sx={{ fontWeight: 900 }}>
                        {category.title}
                    </Typography>
                )}
            </Container>
            <Container maxWidth={'lg'}>
                <Typography component={'div'} variant={'h3'} textAlign={'center'}>
                    {description}
                </Typography> 

                {content && <Typography component={'div'}>{content}</Typography>}
            </Container>
            <Container maxWidth={'xl'} disableGutters>
                <Gallery data={gallery || []} path={galleryPath} length={galleryLength} />
            </Container>

            {location.pathname.includes(pathname.PARTNERS) && (
                <Container maxWidth={false} disableGutters={true}>
                    <Box mt={2} />
                    <Divider />
                    <PartnersLogo />
                </Container>
            )}

            {location.pathname.includes(pathname.DOWNLOAD) && (
                <Container maxWidth={false} disableGutters={true}>
                    <Box mt={2} />
                    <Divider />
                    <FileList data={download} />
                </Container>
            )}

            {list && (
                <Box sx={{ background: '#F2F2F2', py: 4, mt: 4 }}>
                    <Container maxWidth={'xl'}>
                        <Grid container spacing={4}>
                            {list.map((item: { title: string; content: Array<string> }, index: number) => (
                                <Grid key={index} item xs={12}>
                                    <Typography variant={'h4'}>{item.title}</Typography>
                                    <Divider sx={{ my: 2 }} />
                                    {item.content.map((item: string, index: number) => (
                                        <Typography key={index}>{item}</Typography>
                                    ))}
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </Box>
            )}
        </>
    );
};

export default CmsArticle;
